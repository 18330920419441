<template lang="pug">
.footer
  .footer-center
    .top
      .title
        .footer-title
          .pic
            img(src="../assets/cool-icon-white.svg")
          h4.ch 酷東西股份有限公司
        h4.en Cool Stuff Studio
      .social-block
        a.mail(href="mailto:service@coolstuff-studio.com")
          img(src="../assets/footer-mail.svg")
        a.line(href="https://lin.ee/PBYTCkD")
          img(src="../assets/footer-line.svg")
    .contact-block
      .info.tel-info
        span.before Tel
        span.divider |
        span 04-24621082
      .info.mail-info
        span.before Mail
        span.divider |
        span service@coolstuff-studio.com
</template>
<style lang="scss">
.footer{
  width: 100%;
  background-color: #fff;
  padding: 0px 60px;
  border-bottom: solid 2px #000;
  background-color: #000;
  .footer-center{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .top{
      display: flex;
      justify-content: space-between;
      .title{
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        .footer-title{
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .pic{
            width: 20px;
            display: flex;
            align-items: center;
            margin-right: 10px;
            // border: solid 1px #fff;
            height: 100%;
            img{
              height: 80%;
              transform: translateY(1px);
            }
          }
        }
        .en, .ch{
          margin: 0;
          padding: 0;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 1px;
          color: #fff;
          font-weight: 900 !important;
        }
        .ch{
          margin-right: 10px;
        }
        .en{
          margin-bottom: 10px;
        }
      }
      .social-block{
        display: flex;
        .mail, .line{
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 2px #fff;
          padding: 5px;
          border-radius: 3px;
          img{
            height: 100%;
          }
        }
        .mail{
          margin-right: 15px;
        }
      }
    }
    .contact-block{
      .tel-info, .mail-info{
        color: #f4f4f4;
        letter-spacing: 1px;
        font-size: 15px;
      }
      .tel-info{
        margin-bottom: 5px;
      }
      .info{
        .before{
          display: inline-block;
          width: 50px;
        }
        .divider{
          display: inline-block;
          width: 20px;
        }
      }
    }
  }
}
@media (max-width: 1200px){
  .footer{
    padding: 0px 30px;
    .footer-center{
      padding: 40px 0px;
      .title{
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
  }
}
@media (max-width: 380px) {
  .footer{
    .footer-center{
      .top{
        flex-direction: column;
        margin-bottom: 25px;
        .title{
          .footer-title{
            .ch{
              font-size: 16px;
            }
          }
        }
        .social-block{
          .mail, .line{
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}
</style>
<script>
// import { ref } from 'vue'
export default {
  components: {
  },
  setup () {
  }
}
</script>
